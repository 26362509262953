import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Avaleht from "./pages/Webpage1Avaleht";
import Webpage3Cookie_note from "./pages/Webpage3Cookie_note";
import Webpage4Page_not_found from "./pages/Webpage4Page_not_found";
import Webpage9Men_____vaated from "./pages/Webpage9Men_____vaated";
import Webpage11Menu_templates from "./pages/Webpage11Menu_templates";
import Webpage13Home from "./pages/Webpage13Home";

var baseURL = 'https://lilleorumtu.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiZjMzN2FmNjEwMDgxZjkwY2YyYjE4NWZkYTlkNzRkYjE4YmIwODBiOWJmYTI1ZDRiMzJhNWU2YzBjNmNmMmYzMjA3MjI0MjYxYjhkNjVhNmYiLCJpYXQiOjE3MzA4MjU5NjMuMDgzNTM5LCJuYmYiOjE3MzA4MjU5NjMuMDgzNTQ0LCJleHAiOjE3MzA5MTIzNjMuMDc5ODE2LCJzdWIiOiI2NyIsInNjb3BlcyI6W119.q4W5XfhBeZwwJ6KdaF9UukU4qRQRssFXWF6iz6THQ9Yy3l4UMsbYLgq9r1-FwqURroSnr6T_M69tsphzsRgzqchwY8xGtq74x-Xfif1h2UaXYi3YDEwiYwj4mRVUu2uS7imZ6rbkhn6kmprEVOte-ebzgAko8_jSyUY-BsSfC2Nd0wJMN_u9_WZA7PQ9of6IV9ufNOSv0pqWSa7FTKdVmrm19IJfqFqvqGLtgzbe2o1R1weRb69Pl_nLJsZHEceY0Ma3a_QsL8pwpMJfSUj01-WAxRKDjvTGnKJ1JSC8AY7tLHQyyEPSuzKe1ySMkWve2Oi3wRVDAO8SAzYS6PwXaCFt4tnP2_8uqM-hnmXRIerUhLjK0M09TH0jw6CNj-OoSxFiMx4_W-kA_tiBqcjK_yPji0klK7EdUG0FGchIe-A7zryXnrejLxLqC7XR7oA0xajBgAd02dOJKKbSNNbjzf6xITL1nyA7DgP30ow3f6xYUU5UP62NzIeqBYKhmUQUSKv5vVCKrdPeExWZQFS_jNSe0FHqR1L7V9A3BSKD-eXgbumE-YGpy1iAwk9rJNlBM3NqzhpcVsphuRhXh-gM7VKxf_JSD8ebTnq9S00sRYz3y_k3vOOToslTPeMV4kjQbxMoHQR-2UV6G0joVziw5yhY5PjtdE7d44xPYzA91zA',
    'Access-api-key': "eyJpdiI6IkpzK1E2aDJ2TFNNNU54Z3BkZmVLV2c9PSIsInZhbHVlIjoic0ZwVlNQMDNJamwrVi9FSHBuYlcvdz09IiwibWFjIjoiOGFmODY3MGM4MzM2YzVhODQ0MzI4NGNjOTU1YzU4Yzc1MjIwMzZkYjY5Nzc4MTNiOThkMTViNmU4ZjlhNTc4MCIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6Iml3aDV5TEE1aUlidkV6ejlVK0dCUGc9PSIsInZhbHVlIjoicW1FUGdhWWdzTy9Pbm9Zd0VIUHMwZz09IiwibWFjIjoiMjY1Y2ZiNWRiYmI4YTBhNWRlODc5YzI2YWUwOTdlNGIxMGRjZmI4NGUyNTBiNmU4ZWJhMGVkOGFmYmY0YjU5ZCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-WW9QW6"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage1Avaleht />} exact />;
                                                                    <Route path="/en/cookie-note" element={<Webpage3Cookie_note />} exact />;
                                                                    <Route path="/en/*" element={<Webpage4Page_not_found />} exact />;
                                                <Route path="*" element={<Webpage4Page_not_found />} exact />;
                                                                    <Route path="/et/templates" element={<Webpage9Men_____vaated />} exact />;
                                                                    <Route path="/en/templates" element={<Webpage11Menu_templates />} exact />;
                                                                    <Route path="/en/" element={<Webpage13Home />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}